@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

.header-profile {
  /* background-image: url(../public/images/profile.png); */
  background-size: cover;
}

body {
  margin: 0;
  padding: 0;
  background: #fff;

}

/***sidebar css start here***/
.active-menu a {
  background-color: #000 !important;
  color: #fff;
}

.active-menu span,
.active-menu span svg {
  color: #fff !important;
}

div#wrapper {
  padding: 47.9px;
  background-color: #fff;
  gap: 20px;
  padding: 20px;
  height: 100vh;
}

.sidebar_main {
  border: none;
  border-radius: 10px;
  overflow: hidden !important;
  height: 100% !important;
}

.side_menu {
  margin-bottom: 15px !important;
  height: 55vh;
  overflow: scroll;
}

div#top-search-header {
  height: 55px;
  width: 100%;
  background: #f0f3f4;
  border-radius: 10px;
  padding: 18px 30px !important;
  margin-bottom: 20px !important;
  box-shadow: none !important;
}

div#items-loaded {
  background-color: #f0f3f4;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.right_main_div {
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.contain_24 {
  position: relative;
  padding: 20px;
  height: 82.4vh !important;
}

.upload_file_btn {
  position: absolute;
  display: flex;
  justify-content: end;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0 20px;
  z-index: 1;
}

div#top-bar-side svg {
  font-size: 47px;
  color: #000;
}

div#top-bar-side h3 {
  color: #000;
  margin: 0px;
  font-size: 24px !important;
  line-height: 20px !important;
  padding: 0px !important;
}

.css-1vmkajq {
  padding: 0px 5px;
}

.sidebar-holder {
  position: relative;
}

.sidebar_main .ps-sidebar-container {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f0f3f4;
  width: 100%;
  overflow: scroll;
}

.css-1vmkajq {
  margin-bottom: 8px !important;
}

aside.ps-sidebar-root.css-1r2s824 ul.css-ewdv3l li a {
  color: #000 !important;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  border-radius: 53px;
  transition: all 0.5s ease;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

ul.css-ewdv3l {
  padding: 0px;
  margin: 0px !important;
}

ul.css-ewdv3l li {
  margin-bottom: 10px;
}

.css-urhspq>.ps-menu-button:hover {
  background-color: #000 !important;
  color: #fff;
}

a.ps-menu-button:hover span {
  color: #fff !important;
}

a.ps-menu-button:hover span svg {
  color: #fff !important;
}

aside.ps-sidebar-root.css-1r2s824 ul.css-ewdv3l li a svg {
  color: #000;
}

/***user info***/
.sidebar-holder {
  border-top: 2px solid #ccc;
  padding-top: 15px;
  margin-bottom: 0px !important;
}

.user-profile {
  margin-top: 20px;
  /* position: absolute;
  bottom: 0px !important;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; */
}

.user-img img {
  width: 100px;
  height: 100px;
  object-fit: cover !important;
  border-radius: 116px;
  text-align: center;
  margin: 0 auto;
}

.user-details h2 {
  color: #000;
  font-size: 20px;
  text-align: center;
  margin-top: 13px;
  font-weight: 600;
  font-family: "poppins";
  margin-bottom: 3px;
}

.user-details a {
  text-decoration: none;
  text-align: center;
  display: block;
  color: #aba9a9;
  font-size: 16px;
  font-weight: 300;
  font-family: poppins;
}

/***right side added items css**/
button.uploded-btns {
  background: #000;
  border: none;
  margin-top: 26px;
}


/*** right-side description and asked question element css ***/
.desc-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-shadow: 0px 0px 6px #cccccc6b;
  background-color: #fff;
}

h2.chat-wtih {
  font-size: 28px;
  margin-top: 0px;
}

.dsc-container {
  min-height: 130px;
}
.dsc-container p{
  margin:0px 0px;
}

.img-container h2 {
  font-size: 26px !important;
  margin: 10px 0px 0px 0px;

}

.img-container p {
  margin-bottom: 0px !important;
  font-size: 17px;
  color: #c5bcbc;
  font-weight: 400;
  font-family: "poppins";
  margin-top: 4px;

}

.most-asked-q {
  margin-top: 20px;
}

.description {
  margin-top: 20px;
}

.description h3 {
  margin-bottom: 20px !important;
  font-size: 23px;
}

.most-asked-q h3 {
  margin-bottom: 20px !important;
  font-size: 23px;
}

.ques-container {
  min-height: 130px;
}

.ques-container p,.desc-container p{
  margin: 0px 0px;
  font-family:Arial, Helvetica, sans-serif;
  font-size: smaller;
}

.desc-container {
  position: relative;
}

.desc-container svg {
  position: absolute;
  font-size: 31px;
  right: 11px;
  top: 9px;
  color: #a3a1a1;
}

/****.uploaded-document css start here***/
.uploaded-document {
  overflow-y: scroll;
  height: 100%;
}

.modal-content {
  background: #fff;
  border: none;
  border-radius: 25px !important;
}

.modal-body {
  padding-left: 0px;
  padding-right: 0px;
}

.position-relative.modal-header {
  border-bottom: 0px !important;
}

/* .modal-content .modal-body {
  padding-top: 0px !important;
} */

/* .modal.show .modal-dialog {
  transform: translateY(-50%);
  top: 50%;
} */

.close-modals span {
  background: #ff0033;
  border-radius: 50%;
  color: #ffff;
  padding: 5px;
  top: 11px !important;
  right: 11px !important;
  z-index: 10;
}

.css-llrb4p-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:before {
  border-bottom: 0px !important;
  background-color: #fff;
}

.position-relative.close-modals.modal-header {
  padding: 0px;
  padding-top: 20px;
}

.close-modals span svg {
  font-size: 25px;
}

.uploaded-document form {
  margin-top: 18px;
  padding: 8px 25px 25px 25px !important;
  max-width: 80%;
  margin: 0 auto;
}

.uploaded-document h4 {
  font-size: 26px;
  font-family: 'Poppins';
  font-weight: 600;
  text-align: center !important;
  padding: 11px 0px 15px 0px;
  border-bottom: 1px solid #dfdfdf;
}

.uploaded-document form label {
  font-size: 20px;
  color: #262424 !important;
  font-weight: 400 !important;
  font-family: 'Poppins';
  letter-spacing: 1px;
}

.MuiFormControl-root.testing.css-5qgf6x-MuiFormControl-root {
  width: 100% !important;
}

.MuiFormControl-root.css-1pos6ig-MuiFormControl-root {
  width: 100%;
  border-radius: 35px;
}

.upload-btns {
  background: transparent !important;
}

div#organization {
  margin-bottom: 0px !important;
  padding-bottom: 20px;
}

.css-1gzkxga-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input:focus {
  background-color: #fff !important;
}

.uploaded-document input {
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  padding: 7px 15px;
  font-family: 'Poppins';
  font-weight: 400;
}

input#imageFile,
input#pdfFile {
  border: none;
  padding: 5px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  display: none;
}

.img-select label {
  padding: 6px 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background:#fff;
}

.css-llrb4p-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root {
  background: whitesmoke;
  border-bottom: none !important;
  overflow: hidden;
  border-radius: 10px !important;
}

label.text-label.label-form {
  margin-bottom: 10px;
}

.uploaded-document form div {
  margin-bottom: 25px !important;
}

.uploaded-document form div:last-child {
  margin: 0px !important;
  background: #fff;
}

.css-llrb4p-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:before {
  border-bottom: 1px solid #ccc !important;
  background-color: #fff;
}

/**login**/

.user-register-form {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.form-sign-in input {
  border: 1.9px solid #ccc;
  padding: 10px 15px;
  border-radius: 10px;
  color: #000;
  box-shadow: 0px 0px 12px #cccccca8;
}

.sign-form-user label,
div#nav-tabContent label {
  font-size: 20px;
  color: #000 !important;
  font-family: 'Poppins' !important;
  font-weight: 600 !important;
}

.sign-form-user input::placeholder,
div#nav-tabContent input::placeholder {
  color: #000 !important;
  font-family: 'poppins' !important;
  font-size: 16px !important;
}

.sign-form-user button.signup-btn,
form.signup-form button.signup-btn {
  width: 100%;
  background: #03113c !important;
  color: #fff;
  opacity: 1 !important;
  font-weight: 400;
}

div#nav-tab {
  border-bottom: transparent !important;
}

.MuiFormControl-root.css-tmohrq-MuiFormControl-root {
  background: #fff !important;
  width: 100%;
  overflow: hidden;
}

.css-llrb4p-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root {
  background: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
}

.forgets-pass span {
  font-size: 18px;
  color: #000 !important;
  font-family: 'Poppins';
}

.forgets-pass span a {
  text-decoration: none;
  color: #787575;
}

.forgets-pass {
  text-align: center;
  margin-top: 13px;
}

.form-sign-in {
  max-width: 380px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  border-radius: 26px;
  box-shadow: 0px 1px 39px 0px #a1909026;
  overflow: hidden;
  border: 2px solid #e0e0e0;
}

div#nav-tabContent {
  border: none !important;
}

.form-sign-in nav .nav-link {
  max-width: 50%;
  width: 100%;
  border: none;
  border-bottom: 2px solid #dbe1e4;
  padding: 18px 0px;
  font-size: 19px;
  font-weight: 400;
  font-family: 'Poppins';
  color: #787575;
}

.form-sign-in nav .nav-link.active {
  border-bottom: 2px solid #000;
  color: #000 !important;
}

.login-signup-bg {
  padding-top: 0px !important;
}

.form-wrapper {
  background: transparent !important;
  padding: 0px;
  margin: 0px;
}

.form-wrapper {
  padding: 20px;
  background-color: #fff;
  opacity: 0.75;
  border-radius: 20px;
  box-shadow: 5px;
  box-sizing: border-box;
}

.signup-btn {
  padding: 10px 28px;
  border-radius: 10px;
  background-color: #ffffff;
  color: #000;
  cursor: pointer !important;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid #000;
  font-family: 'Poppins';

}

button.signup-btn:hover {
  background: #000 !important;
  color: #fff !important;
  transition: 0.5s ease;
}

.text-field {
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #333;
  outline: none;
}

.header-profile {
  /* background-image: url(../public/images/profile.png); */
  background-size: cover;
}

div#top-search-header input {
  border: none;
  border-radius: 22px;
  padding: 9px 16px;
  font-size: 16px;
  font-family: 'Poppins';
}

div#top-search-header .w-50 {
  max-width: 32% !important;
}

div#top-search-header .input-group .btn {
  position: relative;
  z-index: 2;
  right: 50px;
  background: transparent;
  border: none;
  color: #000;
  top: -1px;
}

div#top-search-header .input-group>.form-control:focus,
div#top-search-header .input-group>.form-floating:focus-within,
div#top-search-header .input-group>.form-select:focus {
  z-index: 1;
  outline: none !important;
  box-shadow: none;
}

.profile-menu {
  border-radius: 10px;
  background-color: #c5bcbc;
  color: #14225f;
  flex-direction: column;
  width: 155px;
  padding: 5px;
  border-top: 3px solid #0867c6;
  z-index: 10;
  position: absolute;
  right: 63px;
  top: 81px;
}

.menu-btn {
  padding: 8px 5px;
  border-radius: 10px;
  display: block;
}

.menu-btn:hover {
  background-color: #f7cece;
}

.css-s2j907.ps-collapsed {
  width: 100px !important;
}

::-webkit-scrollbar {
  width: 1px;
}


.card {
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
  overflow: hidden !important;
  background-color: #ffffff !important;
  cursor: pointer;
  width: 280px;
  height: 220px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card_wrapper_s {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  /* margin-bottom: 50px; */
}

.cart_img_b {
  width: 100%;
  height: 180px;
  padding: 10px;
}

.cart_img_b img {
  width: 100%;
  border-radius: 5px 5px 0 0;
  height: 100%;
  object-fit: cover;
}

.content-box .card h5 {
  margin-bottom: 0px;
}

.content-box .card h5 b {
  font-size: 17px !important;
  font-weight: 500;
  font-family: 'Poppins';
}

.cart_img_b {
  padding-bottom: 0px;
}

.card h5 {
  margin-top: 5px;
}

.container {
  padding: 2px 16px;
}

.page-container {
  height: 100%;
  background: #f0f3f4;
  border-radius: 10px;
}

.content-container .content-box {
  position: relative;
  padding-top: 50px;
  overflow: auto;
}

.pagination_container {
  width: 100%;
  height: auto;
}

.paginationBttn {
  height: auto;
  margin-bottom: 0px;
  margin: 0px;
  padding: 0px;
}

.chat-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0 0;
  border-radius: 0px;
}

div#items-loaded .col-lg-9 {
  padding-right: 0px;
}

div#items-loaded .col-lg-3.col-md-4 {
  padding-left: 0px;
}

.chat-container {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.chat-box {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  padding: 0 15px;

}

.received {
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  color: #000000;
  margin-right: 50px;
}

.message {
  max-width: max-content;
  padding: 10px 20px;
  margin: 12px 0;
  border-radius: 6px;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.8;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
  border: none;
  font-weight: 400;
  font-family: 'Poppins';
  box-shadow: 1px 1px 3px #a3a1a150;
  min-width: 10px;
  width: 60%;
}

.chat-form {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px 25px 15px;
  gap: 8px;
  box-sizing: border-box;
  background-color: transparent;
  border-top: 2px solid #e2e2e2;
}

.chat-form input {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}

.chat-form button {
  border-radius: 6px !important;
}

#chat-input {
  flex-grow: 1;
  padding: 12px;
  border: 2px solid #2b3d52;
  border-radius: 12px;
  font-size: 16px;
  color: #2b3d52;
  transition: border-color 0.3s ease;
}

.send-button {
  padding: 12px 18px;
  background-color: #2b3d52;
  color: #f4f6f8;
  border: none;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.sent {
  background-color: #1b7df5;
  border: none;
  color: #ffff;
  margin-left: auto;
  margin-right: 5px;
}

/* loader css */

.bouncing-loader {
  display: flex;
  margin-top: 25px;
}

.bouncing-loader>div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader>div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader>div:nth-child(3) {
  animation-delay: 0.4s;
}

.custom_loader {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.paginationBttn {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.paginationBttn a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #000000;
  color: #000000;
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 19px;
}

.paginationBttn a:hover {
  color: #fff !important;
  background-color: #000;
}

.paginationActive a {
  color: #fff !important;
  background-color: #000;
}

.previousBttn {
  text-decoration: none;
}

.nextBttn {
  text-decoration: none;
}

.pagination_container {
  margin-top: 22px;

}

.paginationBttn a {
  padding: 3px 10px !important;
  border: none;
  margin: 0px 5px;
  border: 1px solid #000;
  font-size: 14px !important;
}

.paginationBttn a svg {
  font-size: 14px !important;
}

a.previousBttn,
a.nextBttn {
  background: transparent;
  border: 1px solid #000;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 19px;
}


/***chat side css start here***/
.img-user {
  display: flex;
  justify-content: center;
}

.img-user img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 100px;
}

.img-container {
  text-align: center;
  margin: 0 auto;
}

/***media query css start herer***/
@media all and (min-width:1500px) {
  .chat-form {
    padding: 42px 15px 25px 15px;
  }
}

@media all and (max-width:1400px) {
  .img-container h2 {
    font-size: 20px !important;
    line-height: 100%;
  }

  /* .dsc-container,
  .ques-container {
    border: 3px solid #bdbdbd;
    min-height: 90px;
  } */

  .desc-container h3 {
    font-size: 18px;
    margin-bottom: 13px !important;
  }

  .user-profile {
    margin-top: 20px;
  }

  ul.css-ewdv3l li {
    margin-bottom: 3px;
  }

  aside.ps-sidebar-root.css-1r2s824 ul.css-ewdv3l li a {
    font-size: 15px;
  }

  .sidebar-holder {
    padding-top: 0px !important;
  }

  a.ps-menu-button {
    height: 43px !important;
  }

  /* .pagination_container {
    bottom: 30px;
  } */

  .chat-section {
    width: 100%;
    overflow: scroll;
    height: 100%;
  }

  .desc-container {
    height: 100vh;
    overflow: scroll;
  }

  .img-user img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 100px;
  }

  .img-container h2 {
    font-size: 16px !important;
    line-height: 100%;
  }

  .img-container p {
    font-size: 14px;
  }

  .desc-container h3 {
    font-size: 15px;
    margin-bottom: 9px !important;
  }

  div#top-search-header {
    padding: 18px 20px !important;
  }

  div#top-search-header input {
    padding: 5px 16px !important;
  }

  div#top-bar-side svg {
    font-size: 35px;
    color: #000;
  }

  .css-1vmkajq {
    margin-top: 6px !important;
    height: 40px !important;
    min-height: 40px !important;
  }

  div#top-bar-side h3 {
    font-size: 18px !important;

  }

  .user-img img {
    width: 80px;
    height: 80px;
  }

  .user-details h2 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .user-profile {
    margin-top: 0px !important;
  }

  .cart_img_b {
    height: auto !important;
  }

  .cart_img_b img {
    height: 88px;
  }

  .card {
    width: 18% !important;
    height: 135px !important;
  }

  .user-register-form {
    height: 100%;
    padding: 0px 0px;
  }

  .pagination_container {
    bottom: 30px !important;
  }

  .content-box .card h5 b {
    font-size: 14px !important;
  }

  .card h5 {
    margin-top: 3px !important;
  }

}

@media all and (max-width:1100px) {
  div#top-search-header .w-50 {
    max-width: 60% !important;
  }

  .header-profile {
    width: 45px !important;
    height: 45px !important;
  }
}